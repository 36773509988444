
import 'bootstrap/dist/css/bootstrap.min.css';
import  React  from "react"
import { Link, StaticQuery, graphql } from "gatsby"
import HomePage from "../pages/homePage";
import { Helmet } from 'react-helmet';
import "./index.less";
import SEO from '../components/head/seo';
const IndexPage = () => (

  <>
    <SEO/>
    <HomePage/>
  </>
)

export default IndexPage;
